import MENTAL_HEALTH from '../assets/icons/Therapy_MH.svg'
import SPEECH_THERAPY from '../assets/icons/Therapy_ST.svg'
import OCCUPATIONAL_THERAPY from '../assets/icons/Therapy_OT.svg'
import SCHOOL_PSYCHOLOGY from '../assets/icons/Therapy_SP.svg'
import type { ServiceLine } from '../types/ServiceLine'

export enum ServiceLineKeys {
  ALL = 'ALL',
  SOCIAL_WORK = 'SOCIAL_WORK',
  MENTAL_HEALTH = 'MENTAL_HEALTH',
  MENTAL_HEALTH_THERAPY = 'MENTAL_HEALTH_THERAPY',
  SPEECH_THERAPY = 'SPEECH_THERAPY',
  OCCUPATIONAL_THERAPY = 'OCCUPATIONAL_THERAPY',
  SCHOOL_PSYCHOLOGY = 'SCHOOL_PSYCHOLOGY',
  AUDIOLOGY_TREATMENT = 'AUDIOLOGY_TREATMENT',
  AUDIOLOGY = 'AUDIOLOGY',
  COUNSELING = 'COUNSELING',
  PSYCHOLOGY = 'PSYCHOLOGY',
  APPLIED_BEHAVIOR_ANALYSIS = 'APPLIED_BEHAVIOR_ANALYSIS',
  SOCIAL_EDUCATION = 'SOCIAL_EDUCATION',
  SPECIAL_EDUCATION = 'SPECIAL_EDUCATION',
}

export const SERVICE_LINES_ARRAY: ServiceLine[] = [
  {
    displayName: 'Mental Health Therapy',
    url: 'mental-health',
    serviceType: ServiceLineKeys.SOCIAL_WORK,
    serviceId: 4,
    avatar: MENTAL_HEALTH,
    bgColor: 'bg-components-paleBlue',
    type: 'mental health',
    orderId: 1,
    borderColor: 'border-secondaryAccents-lightGreen',
  },
  {
    displayName: 'Speech Therapy',
    url: 'speech',
    serviceType: ServiceLineKeys.SPEECH_THERAPY,
    serviceId: 0,
    avatar: SPEECH_THERAPY,
    bgColor: 'bg-background-beige',
    type: 'speech',
    orderId: 2,
    borderColor: 'border-secondaryAccents-lightOrange',
  },
  {
    displayName: 'Occupational Therapy',
    url: 'occupational',
    serviceType: ServiceLineKeys.OCCUPATIONAL_THERAPY,
    serviceId: 1,
    avatar: OCCUPATIONAL_THERAPY,
    bgColor: 'bg-background-beige',
    type: 'occupational',
    orderId: 3,
    borderColor: 'border-primaryAccents-orange',
  },
  {
    displayName: 'Audiology',
    url: 'audiology',
    serviceType: ServiceLineKeys.AUDIOLOGY,
    serviceId: 2,
    avatar: MENTAL_HEALTH,
    bgColor: 'bg-components-paleBlue',
    type: 'audiology',
    orderId: 4,
    borderColor: 'border-secondaryAccents-lightGreen',
  },
  {
    displayName: 'School Psychology',
    url: 'psychology',
    serviceType: ServiceLineKeys.PSYCHOLOGY,
    serviceId: 5,
    avatar: SCHOOL_PSYCHOLOGY,
    bgColor: 'bg-components-paleBlue',
    type: 'psychology',
    orderId: 4,
    borderColor: 'border-secondaryAccents-lightGreen',
  },
]
