import {
  ClockIcon,
  ExclamationIcon,
  LocationMarkerIcon,
} from '@heroicons/react/outline'
import { RefreshIcon } from '@heroicons/react/solid'
import React, { useEffect, useMemo, useState } from 'react'
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'
import {
  parse,
  isWithinInterval,
  isSaturday,
  isSameDay,
  isSunday,
  format,
  addMonths,
  isBefore,
} from 'date-fns'
import { useParams } from 'react-router-dom'

import { usePatient } from '../../../contexts/PatientProvider'
import useCurrentServiceLine from '../../../hooks/useCurrentServiceLine'
import { useGetAppointmentSlots } from '../../../queries/booking/GetAppointmentSlots'
import type { ServiceLine } from '../../../types/ServiceLine'
import AppointmentAvailabilityCalendar from './AppointmentAvailabilityCalendar'
import type { SelectedDateAndSlot, TimeSlot } from '../../../types/Booking'
import { HOUR12_REGEX } from '../../../constants/regex'
import { useAuth } from '../../../contexts/AuthProvider'
import type { CarePlan } from '../../../types/Patient'

const CalendarWithSlots: React.FC<{
  setSelectedDateAndSlot: React.Dispatch<
    React.SetStateAction<SelectedDateAndSlot>
  >
  sessionDuration: number
  isAssessment: boolean
  id?: number
  activeCalendar?: number
  setActiveCalendar?: React.Dispatch<React.SetStateAction<number>>
  hoursNotice?: number
}> = ({
  setSelectedDateAndSlot,
  sessionDuration,
  isAssessment,
  id,
  activeCalendar,
  setActiveCalendar,
  hoursNotice,
}) => {
  const { user } = useAuth()
  const { providerId } = useParams()
  const { patient } = usePatient()
  const serviceLine: ServiceLine = useCurrentServiceLine()
  const currentCarePlan: CarePlan = patient?.carePlans.find(
    (cp: CarePlan) => !cp.isIep && cp.displayName === serviceLine.displayName
  )
  const [isCalendarVisible, setIsCalendarVisible] = useState(false)
  const [checkboxConflictHoursChecked, setCheckboxConflictHoursChecked] =
    useState(false)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date())
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot>(null)

  const handleShowCalendar = (): void => {
    if (isCalendarVisible) return
    if (id !== undefined) setActiveCalendar(id)
    setIsCalendarVisible(true)
  }

  const { data: appointmentSlots, isFetching: isLoadingAppointmentSlots } =
    useGetAppointmentSlots({
      providerId,
      sessionDuration,
      patient,
      hoursNotice,
      date: format(selectedMonth, 'yyyy-MM'),
      enabled:
        isCalendarVisible && isBefore(selectedMonth, addMonths(new Date(), 2)),
    })

  const isWithinSchoolHours = useMemo((): boolean => {
    if (!selectedTimeSlot || !user || !patient) return false

    const startStr = HOUR12_REGEX.test(
      user?.data?.clientData?.clientSchoolStart
    )
      ? user.data.clientData.clientSchoolStart
      : '07:00 AM'

    const endStr = HOUR12_REGEX.test(user?.data?.clientData?.clientSchoolEnd)
      ? user.data.clientData.clientSchoolEnd
      : '03:30 PM'

    const given = parse(
      formatInTimeZone(
        selectedTimeSlot.startOriginal,
        patient.timeZone,
        'hh:mm aa'
      ),
      'hh:mm aa',
      selectedDate
    )
    const start = parse(startStr, 'hh:mm aa', selectedDate)
    const end = parse(endStr, 'hh:mm aa', selectedDate)

    return (
      isWithinInterval(given, { start, end }) &&
      !isSaturday(given) &&
      !isSunday(given)
    )
  }, [selectedTimeSlot, user, patient])

  // temprorary disable this to see if it's okay
  // // select first available date
  // useEffect(() => {
  //   if (!appointmentSlots) return

  //   const firstAvailableDate = appointmentSlots.find(
  //     (slot) => new Date(slot.startOriginal) > new Date()
  //   )

  //   if (firstAvailableDate) {
  //     setSelectedDate(new Date(firstAvailableDate.startOriginal))
  //     setSelectedMonth(new Date(firstAvailableDate.startOriginal))
  //   }
  // }, [appointmentSlots])

  // reset the selected time slot when another day is selected
  useEffect(() => {
    if (!selectedDate) return
    setSelectedMonth(selectedDate)
    setSelectedTimeSlot(null)
    setCheckboxConflictHoursChecked(false)
  }, [selectedDate])

  // listen to active calendar change in order to close the inactive one when working with multiple calendars
  useEffect(() => {
    if (id === undefined) return

    if (activeCalendar !== id) {
      setIsCalendarVisible(false)
      setCheckboxConflictHoursChecked(false)
      setSelectedTimeSlot(null)
      setSelectedDateAndSlot(null)
    }
  }, [activeCalendar])

  // set resulting date and time slot when everything is ok
  useEffect(() => {
    if (
      !selectedTimeSlot ||
      (isWithinSchoolHours && !checkboxConflictHoursChecked)
    )
      setSelectedDateAndSlot(null)
    else setSelectedDateAndSlot({ selectedDate, selectedTimeSlot })
  }, [selectedTimeSlot, isWithinSchoolHours, checkboxConflictHoursChecked])

  return (
    <div
      className={`flex min-w-calendar-sm flex-col items-center gap-4 rounded-2xl border border-cta-default bg-white px-2 py-4 sm:min-w-calendar-lg sm:gap-8 sm:p-6 ${
        isCalendarVisible ? 'cursor-default' : 'cursor-pointer'
      }`}
      onClick={handleShowCalendar}
      role="presentation"
    >
      {/* Calendar not expanded */}
      <div className="flex flex-col gap-1 text-center text-text-secondary">
        <p className="text-sm font-semibold sm:text-base">
          {serviceLine.displayName}{' '}
          {isAssessment
            ? `Assessment - ${sessionDuration} Min`
            : `Session - ${sessionDuration} Min`}
        </p>
        {currentCarePlan?.remainingSessions > 0 &&
          Boolean(currentCarePlan.allowedSessions) && (
            <p className="text-sm font-normal text-text-secondary">
              Counts as {Math.floor(sessionDuration / 30)} session
              {Math.floor(sessionDuration / 30) > 1 ? 's' : ''}
            </p>
          )}
        <div className="flex flex-row items-center justify-center gap-0.5">
          <LocationMarkerIcon className="h-3.5 w-3.5" />
          <p className="text-sm font-normal text-text-secondary">Zoom</p>
        </div>
      </div>

      {/* Expanded */}
      {isCalendarVisible && (
        <>
          <AppointmentAvailabilityCalendar
            outsideGivenSelectedDate={selectedDate}
            onChangeInternalSelectedDate={setSelectedDate}
            isLoadingAppointmentSlots={isLoadingAppointmentSlots}
            appointmentSlots={appointmentSlots}
            setSelectedMonth={setSelectedMonth}
          />

          {/* Time Slots */}
          {isLoadingAppointmentSlots ? (
            <div className="flex w-full items-center justify-center">
              <p className="flex items-center gap-1 text-base text-text-primary xs:text-sm">
                <RefreshIcon className="loader h-5 w-5" />
                Loading..
              </p>
            </div>
          ) : (
            Boolean(appointmentSlots?.length) &&
            selectedDate && (
              <div className="flex flex-col gap-2 self-stretch sm:gap-4">
                {/* Selected date */}
                <p className="text-sm font-semibold text-text-label sm:text-base">
                  {format(selectedDate, 'MM/dd/yyyy')}
                </p>

                {/* Timezone display */}
                <div className="flex flex-row items-center justify-center gap-1">
                  <ClockIcon className="h-4 w-4 text-text-label" />
                  <p className="text-xs text-text-secondary sm:text-sm">
                    {`${formatInTimeZone(
                      new Date(),
                      patient.timeZone,
                      'zzzz'
                    )} (${formatInTimeZone(
                      new Date(),
                      patient.timeZone,
                      'zzz'
                    )})`}
                  </p>
                </div>

                {/* Actual times */}
                <div className="grid min-w-max grid-cols-3 gap-2 sm:max-h-44 sm:grid-cols-4 sm:gap-x-2 sm:gap-y-4 sm:overflow-y-auto sm:pr-2.5 sm:scrollbar:!h-2 sm:scrollbar:!w-2 sm:scrollbar:bg-transparent sm:scrollbar-track:!rounded-full sm:scrollbar-track:!bg-transparent sm:scrollbar-thumb:!rounded-full sm:scrollbar-thumb:!bg-components-fields">
                  {React.Children.toArray(
                    appointmentSlots
                      // only slots for selected date
                      ?.filter((slot) => {
                        // on the same day
                        const zonedTime = zonedTimeToUtc(
                          slot.startOriginal,
                          patient.timeZone
                        )

                        return isSameDay(zonedTime, selectedDate)
                      })
                      // display
                      ?.map((slot: TimeSlot) => (
                        <button
                          onClick={() => setSelectedTimeSlot(slot)}
                          className={`rounded-2xl p-3 sm:p-4 ${
                            selectedTimeSlot &&
                            slot.startOriginal ===
                              selectedTimeSlot.startOriginal
                              ? 'border border-cta-default bg-components-paleBlue'
                              : 'border border-transparent bg-components-fillBorders'
                          }`}
                        >
                          <p
                            className={`text-sm font-semibold sm:text-base ${
                              selectedTimeSlot &&
                              slot.startOriginal ===
                                selectedTimeSlot.startOriginal
                                ? 'text-cta-default'
                                : 'text-text-primary'
                            }`}
                          >
                            {formatInTimeZone(
                              slot.startOriginal,
                              patient.timeZone,
                              'hh:mm aa'
                            )}
                          </p>
                        </button>
                      ))
                  )}
                </div>

                {/* School hours check */}
                {isWithinSchoolHours && (
                  <div className="mt-6 flex w-full flex-col items-center justify-center gap-4 rounded-lg bg-background-offwhite px-4 py-2 xs:gap-2">
                    <div className="flex flex-wrap items-center gap-1">
                      <ExclamationIcon className="h-6 w-6 text-status-error" />
                      <p className="text-sm font-semibold xs:text-xs">
                        This session might conflict with school hours
                      </p>
                    </div>
                    <div className="flex flex-row gap-2">
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded-md border border-components-fields text-cta-default focus:ring-cta-default"
                        checked={checkboxConflictHoursChecked}
                        onChange={() =>
                          setCheckboxConflictHoursChecked(
                            (crt: boolean): boolean => !crt
                          )
                        }
                      />
                      <p className="text-left text-xs font-normal sm:text-sm">
                        I understand and will manage coordination
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )
          )}
        </>
      )}
    </div>
  )
}

export default CalendarWithSlots
