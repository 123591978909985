import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  RefreshIcon,
  SearchIcon,
  XIcon,
} from '@heroicons/react/solid'
import cloneDeep from 'lodash.clonedeep'
import { flushSync } from 'react-dom'

import Stepper from '../../components/Stepper'
import { usePatient } from '../../contexts/PatientProvider'
import {
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import { useAuth } from '../../contexts/AuthProvider'
import { ONBOARDING_STEP, type Patient } from '../../types/Patient'
import type { InterestOption } from './WhatBringsYouHere'
import { useSaveInterest } from '../../mutations/onboarding/SaveInterests'
import { useGetResourceTopics } from '../../queries/resources/GetResourceTopics'
import type { ResourceTopic } from '../../types/Resources'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'

const MIXPANEL_DATA = {
  eventName: MIXPANEL_EVENT.ONBOARDING_STEP_COMPLETED,
  properties: { step: ONBOARDING_STEP.interest },
}

const InterestScreen: React.FC = () => {
  const [searchParams] = useSearchParams()
  const signUpFromEmail = searchParams?.get('signUpFromEmail') == 'true'
  const { user, setUser } = useAuth()
  const { patient, setPatient } = usePatient()
  const forSelf = patient?.relationship?.key === 'myself'
  const navigate = useNavigate()
  const [selectedTopics, setSelectedTopics] = useState<ResourceTopic[]>(
    patient?.interests || []
  )

  const isInterestedInTherapy: boolean =
    user.data.whatBringsYouHere.some(
      (o: InterestOption) => o?.name === 'Therapy'
    ) ||
    patient?.allowedToBook ||
    patient?.isEligible

  const { mutate: callSaveInterests, isLoading: isLoadingSavingInterests } =
    useSaveInterest()

  // save interest and go to area of focus or dashboard
  const handleNext = () =>
    callSaveInterests(
      {
        patientId: patient.id,
        interests: selectedTopics.filter(
          (t: ResourceTopic) => t.key !== 'OTHER'
        ),
      },
      {
        onSettled: (interests: ResourceTopic[]) => {
          trackMixPanel({
            eventName: MIXPANEL_DATA.eventName,
            properties: {
              ...MIXPANEL_DATA.properties,
              patient: patient?.firstName + ' ' + patient?.lastName,
              topics: selectedTopics.map((t) => t.key).join(','),
            },
          })

          const nextIsAreaOfFocus: boolean =
            isInterestedInTherapy &&
            patient.isEligible &&
            !patient?.carePlans?.some((c) => !c.isIep)

          const newPatient: Partial<Patient> = {
            ...patient,
            interests,
            onboardingStep: nextIsAreaOfFocus
              ? ONBOARDING_STEP.areaFocus
              : ONBOARDING_STEP.booking,
          }
          const newUser = cloneDeep(user)
          const patientIdx = newUser.roster.findIndex(
            (p: Patient) => p.id === patient.id
          )
          newUser.roster[patientIdx] = newPatient
          flushSync(() => {
            setUser(newUser)
            setPatient(newPatient)

            if (newPatient.isSeparateLogin) navigate('/dashboard')
            else if (nextIsAreaOfFocus)
              navigate(
                `/onboarding/area-focus?signUpFromEmail=${signUpFromEmail}`
              )
            else if (user?.isKiddo) navigate('/dashboard', { replace: true })
            else navigate('/onboarding/add-another-person')
          })
        },
      }
    )

  // scroll to top on load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="max-w-xl">
      {/* Stepper */}
      <div className="mx-auto">
        <Stepper
          steps={
            isInterestedInTherapy &&
            user.data.clientData.clientType !== 'EDUCATION'
              ? [
                  { name: '1', status: 'complete' },
                  { name: '2', status: 'complete' },
                  { name: '3', status: 'current' },
                  { name: '4', status: '' },
                ]
              : [
                  { name: '1', status: 'complete' },
                  { name: '2', status: 'complete' },
                  { name: '3', status: 'current' },
                ]
          }
        />
      </div>

      {/* Header */}
      <div className="flex flex-col items-center gap-6 text-center">
        <p className="text-center text-base font-semibold sm:text-2xl">
          I need{' '}
          {forSelf ? (
            'support'
          ) : (
            <span>
              help supporting{' '}
              <span className="text-cta-default">{patient?.firstName}</span>
            </span>
          )}{' '}
          with
        </p>
        <p className="text-center text-sm sm:text-base">
          Please select all that apply
        </p>
      </div>

      {/* Interests */}
      <InterestsBody
        selectedTopics={selectedTopics}
        setSelectedTopics={setSelectedTopics}
      />

      {/* Buttons */}
      <div className="flex w-full gap-2 sm:gap-4">
        <button
          type="button"
          disabled={isLoadingSavingInterests}
          className={`w-1/2 ${tertiaryButtonClass}`}
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon className="h-5 w-5" />
          Back
        </button>
        <button
          type="button"
          className={`w-1/2 ${primaryButtonClass}`}
          onClick={handleNext}
          disabled={!selectedTopics?.length || isLoadingSavingInterests}
        >
          {isLoadingSavingInterests ? (
            <>
              Loading
              <RefreshIcon className="loader h-5 w-5" />
            </>
          ) : (
            <>
              Next
              <ChevronRightIcon className="h-5 w-5 text-white" />
            </>
          )}
        </button>
      </div>

      {!isLoadingSavingInterests && (
        <SkipAndComeBackLater
          mixPanelData={{
            ...MIXPANEL_DATA,
            properties: {
              ...MIXPANEL_DATA.properties,
              skipped: true,
            },
          }}
        />
      )}
    </div>
  )
}

export const InterestsBody: React.FC<{
  selectedTopics: ResourceTopic[]
  setSelectedTopics: React.Dispatch<React.SetStateAction<ResourceTopic[]>>
}> = ({ selectedTopics, setSelectedTopics }) => {
  const [interests, setInterests] = useState<ResourceTopic[]>([])
  const searchInputRef = useRef(null)

  const { data: interestTopics, isLoading: isLoadingTopics } =
    useGetResourceTopics()

  const sections = [
    ...new Set(interestTopics?.map((topic: ResourceTopic) => topic.section)),
  ]

  const handleSearchByKeywords = (searchValue: string) => {
    if (!searchValue) {
      setInterests(interestTopics)
      return
    }

    setInterests(
      interestTopics.filter(
        (i: ResourceTopic) =>
          i?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          i?.name === 'Other'
      )
    )
  }

  const handleClickOnInterest = (interest: ResourceTopic) => {
    // add other interest to initial interests and mark it as active
    if (
      !interests.some(
        (t: ResourceTopic) =>
          t.key === interest.key &&
          t?.name === interest?.name &&
          t.section === interest.section
      )
    ) {
      setInterests((interests) => [...interests, interest])
      setSelectedTopics((selectedTopics) => [...selectedTopics, interest])
    } else {
      if (
        selectedTopics.some(
          (t: ResourceTopic) =>
            t.key === interest.key &&
            t.section === interest.section &&
            t.name === interest.name
        )
      )
        setSelectedTopics((selectedTopics) =>
          selectedTopics.filter(
            (t: ResourceTopic) =>
              t.key !== interest.key ||
              t.section !== interest.section ||
              t.name !== interest.name
          )
        )
      else setSelectedTopics((selectedTopics) => [...selectedTopics, interest])
    }
  }

  useEffect(() => {
    if (isLoadingTopics) return

    setInterests([
      ...interestTopics,
      ...selectedTopics.filter((t: InterestOption) => t.key.includes('OTHER')),
    ])

    window.scrollTo(0, 0)
  }, [isLoadingTopics])

  return (
    <div className="flex w-full flex-col gap-6">
      {/* Search bar */}
      <div className="flex flex-col gap-1">
        <p className="text-base font-semibold">Search by keyword</p>
        <div className="flex w-full items-end gap-4 sm:justify-between xs:flex-wrap">
          <div className="relative w-full">
            <input
              ref={searchInputRef}
              className={`${inputValidClass} !pl-10`}
              placeholder="Search by keyword"
              onChange={(e) => handleSearchByKeywords(e.target.value.trim())}
            />
            <SearchIcon className="absolute left-4 top-1/2 h-5 w-5 -translate-y-1/2 text-components-fields" />
            {searchInputRef?.current?.value.trim() && (
              <XIcon
                className="absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 cursor-pointer text-components-fields"
                onClick={() => {
                  searchInputRef.current.value = ''
                  handleSearchByKeywords('')
                }}
              />
            )}
          </div>
        </div>
      </div>

      {/* No interests found */}
      {!isLoadingTopics &&
        interests.every(
          (i: InterestOption) => i.key === 'OTHER' && i.name === 'Other'
        ) && (
          <p className="whitespace-pre text-center text-base xs:text-sm">
            Sorry, we couldn't find anything matching your search.{'\n'}Please
            try different keywords or add them by selecting 'Other'.
          </p>
        )}

      {/* Sections */}
      {!interests?.length ? (
        <div className="flex items-center justify-center gap-2 font-semibold">
          <RefreshIcon className="loader h-5 w-5" />
          <p>Loading topics..</p>
        </div>
      ) : (
        React.Children.toArray(
          sections.map((sectionTitle: string) => (
            <InterestBox
              title={sectionTitle}
              topics={interests.filter(
                (t: ResourceTopic) => t.section === sectionTitle
              )}
              handleClickOnInterest={handleClickOnInterest}
              selectedTopics={selectedTopics}
            />
          ))
        )
      )}
    </div>
  )
}

export const InterestBox: React.FC<{
  title: string
  topics: ResourceTopic[]
  handleClickOnInterest: (interest: ResourceTopic) => void
  selectedTopics: ResourceTopic[]
}> = ({ title, topics, handleClickOnInterest, selectedTopics }) => {
  const [expanded, setExpanded] = useState<boolean>(true)
  const [otherTopicName, setOtherTopicName] = useState<string>('')

  const addOtherTopic = () => {
    const otherTopicValue = otherTopicName.trim()
    if (!otherTopicValue) return

    handleClickOnInterest({
      key: `OTHER_${title.toUpperCase()}`,
      name: otherTopicValue,
      section: title,
      other: true,
    })

    setOtherTopicName('')
  }

  return (
    <div
      className={
        'flex flex-col gap-4 rounded-lg border border-components-fields bg-white p-4 xs:p-2'
      }
    >
      {/* Header */}
      <button
        onClick={() => setExpanded(!expanded)}
        className={`flex w-full justify-center gap-1 ${
          expanded && 'border-b-4 border-b-components-paleBlue pb-4 xs:pb-0'
        }`}
      >
        <p className="text-center text-base font-semibold xs:text-sm">
          {title}
        </p>
        {expanded ? (
          <ChevronUpIcon className="h-6 w-6 text-cta-default" />
        ) : (
          <ChevronDownIcon className="h-6 w-6 text-cta-default" />
        )}
      </button>

      {/* Pills */}
      {expanded && (
        <div className="flex flex-wrap gap-4">
          {React.Children.toArray(
            topics
              .sort((a, b) => {
                if (a.key === 'OTHER') {
                  return 1
                } else if (b.key === 'OTHER') {
                  return -1
                }
                return 0
              })
              .map((t: ResourceTopic) => (
                <button
                  onClick={() => handleClickOnInterest(t)}
                  className={`rounded-xl border p-3 xs:text-xs ${
                    selectedTopics.some(
                      (st: ResourceTopic) =>
                        st.key === t.key &&
                        st.section === title &&
                        st.name === t.name
                    )
                      ? 'border-cta-default bg-components-paleBlue text-cta-default'
                      : 'border-transparent bg-components-fillBorders'
                  }`}
                >
                  <p className="font-semibold">{t.name}</p>
                </button>
              ))
          )}
        </div>
      )}

      {/* Add another topic */}
      {expanded &&
        selectedTopics.find(
          (t: ResourceTopic) => t.name === 'Other' && t.section === title
        ) && (
          <div className="flex flex-col gap-1">
            <p className="text-base font-semibold">
              Please enter topic(s) of interest
            </p>
            <div className="flex gap-2">
              <input
                onChange={(e) => setOtherTopicName(e.currentTarget.value)}
                className={inputValidClass}
                placeholder="Enter"
                value={otherTopicName}
              />
              <button
                disabled={!otherTopicName}
                onClick={addOtherTopic}
                className="mt-2 flex items-center gap-1 self-center text-base font-semibold text-cta-default disabled:text-cta-disabled"
              >
                ADD
              </button>
            </div>
          </div>
        )}
    </div>
  )
}

export default InterestScreen
