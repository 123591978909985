import { useMutation } from '@tanstack/react-query'
import { format } from 'date-fns'

import { auth } from '../../config/firebase'

export type ProviderInfo = {
  id: string
  email: string
  firstName: string
  serviceLine: string
  clinicalManagerId: string
}

interface DuressProps {
  providers: ProviderInfo[]
  patientFullName: string
  answer: string
  clientName: string
}

const sendDuressEmail = async ({
  providers,
  patientFullName,
  answer,
  clientName,
}: DuressProps) => {
  try {
    const token: string = await auth.currentUser.getIdToken()

    await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_SURVEYS_URL
      }/v1/survey/send-phq9-risk-concern-email`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          providers,
          patientFullName,
          answer,
          submittedOn: format(new Date(), 'MM/dd/yyyy'),
          clientName,
        }),
      }
    )
  } catch {
    throw new Error('Something went wrong.')
  }
}

export const useCallDuressEmail = () =>
  useMutation((params: DuressProps) => sendDuressEmail(params))
