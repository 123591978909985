import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import { toCamelCase } from '../../helpers/serializers'
import { toTherapist } from '../../helpers/transformers'
import type { Patient } from '../../types/Patient'
import type { ServiceLine } from '../../types/ServiceLine'
import type { Therapist } from '../../types/Therapist'

interface GetProvidersProps {
  patients: Partial<Patient>[]
  ignoreTakingNewPrivatePatients?: boolean
  sortBy?: string
  withFirstAvailability?: boolean
  withServiceType?: boolean
  withLanguage?: boolean
  withLicenseJurisdiction?: boolean
  enabled?: boolean
  onlyServiceLines?: ServiceLine[]
  clientId?: string
  licenseJurisdiction?: string
  language?: string
}

export interface ResultGetProviders {
  patient: Partial<Patient>
  availableServiceLinesNames: string[]
  therapists: Therapist[]
}

export const fetchProviders = async (params: GetProvidersProps) => {
  const token: string = await auth.currentUser.getIdToken()

  const response = await fetch(
    `${import.meta.env.VITE_DOTCOM_BFF_URL}providers`,
    {
      method: 'POST',
      body: JSON.stringify({
        eocIds: params.patients.flatMap((p) => p.carePlans?.map((cp) => cp.id)),
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  const result: ResultGetProviders[] = []

  const resultProviders: Therapist[] = response?.map((provider: any) =>
    toTherapist(toCamelCase(provider))
  )

  const therapists: Therapist[] = params.withFirstAvailability
    ? resultProviders.filter((p: Therapist) => Boolean(p.firstAvailability))
    : resultProviders

  params.patients.forEach((patient) => {
    result.push({
      patient,
      availableServiceLinesNames: Array.from(
        new Set(therapists.map((t: Therapist) => t.serviceLine))
      ),
      therapists,
    })
  })

  return result
}

const useGetProviders = (params: GetProvidersProps) =>
  useQuery(
    [
      'getProviders',
      params.patients.map((p) => p?.carePlans?.map((cp) => cp.id)),
      params.withFirstAvailability,
    ],
    async () => fetchProviders(params)
  )

export default useGetProviders
