import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import SessionRatingModal from '../../../components/Modals/SessionRatingModal'
import SessionComponent from '../../../components/SessionComponent'
import { secondaryButtonClass } from '../../../constants/classConstants'
import { useAuth } from '../../../contexts/AuthProvider'
import { enumeratedElementsFromArray, newDate } from '../../../helpers/generic'
import type { CarePlan, Patient } from '../../../types/Patient'
import type { Session } from '../../../types/Session'
import DashboardElement from '../DashboardElement'
import LIBRARY_ICON from '../../../assets/icons/LibraryColored.svg'
import type { ResultGetProviders } from '../../../queries/booking/UseGetProviders'
import useIsIEPOnly from '../../../hooks/useIsIEPOnly'
import BookNewSessionBtn from '../../view-patient/tabs/BookNewSessionBtn'
import handleClickSupportEmail from '../../../helpers/supportClick'

const Sessions: React.FC<{
  resultProviders: ResultGetProviders[]
}> = ({ resultProviders }) => {
  const { user } = useAuth()
  const isIepOnlyExperience = useIsIEPOnly()
  const [isDismissedMessaging, setIsDismissedMessaging] =
    useState<boolean>(false)
  const [sessions, setSessions] = useState<Session[]>([])
  const [sessionToRate, setSessionToRate] = useState<Session>(null)
  const [openSessionRating, setOpenSessionRating] = useState<boolean>(false)
  const dontShowBookNewSessionBtn = !resultProviders?.some(
    (el: ResultGetProviders) => el.therapists?.length
  )

  // get upcoming sessions and session to rate
  useEffect(() => {
    if (!user.roster?.length) return

    let upcomingSessions: Session[] = []

    user?.roster?.forEach((patient: Patient) => {
      patient?.carePlans?.forEach((c: CarePlan) => {
        const onlyUpcomingSessions = c?.sessions?.filter(
          (session: Session) =>
            session.status === 'Pending' &&
            newDate(new Date(session.endTime), patient.timeZone) >
              newDate(new Date(), patient.timeZone)
        )

        upcomingSessions = upcomingSessions.concat(onlyUpcomingSessions)

        // session that is completed && !rated
        const unratedSession: Session = c?.sessions?.find(
          (s: Session) =>
            s.status === 'Completed' &&
            s.rating === null &&
            s.scheduledBy.id === user.data.id
        )

        if (Boolean(unratedSession) && !c.isIep) {
          setSessionToRate(unratedSession)
          setOpenSessionRating(Boolean(unratedSession))
        }
      })
    })

    setSessions(
      upcomingSessions
        .sort(
          (a, b) =>
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        )
        .slice(0, 2)
    )
  }, [user])

  useEffect(() => {
    if (!user?.roster?.length) return

    const initialOnboardingDone = Boolean(
      localStorage.getItem('DOTCOM_INITIAL_IEP_ONLY_PATIENTS')
    )

    if (initialOnboardingDone) return

    const onboardedIEPOnly = user?.roster?.every(
      (patient: Patient) => patient?.isIepOnly
    )

    localStorage.setItem(
      'DOTCOM_INITIAL_IEP_ONLY_PATIENTS',
      String(onboardedIEPOnly)
    )
  }, [user])

  const iepPatientNames: string[] = user.roster
    .filter((p: Patient) => p?.isIep && Boolean(p?.state))
    .map((p: Patient) =>
      p?.relationship?.key === 'myself' ? 'you' : p.firstName
    )

  const dismisssedComponent = Boolean(
    localStorage.getItem('DOTCOM_DISMISSED_ADDITIONAL_CARE')
  )

  const initialOnboardingForIEPOnly =
    localStorage.getItem('DOTCOM_INITIAL_IEP_ONLY_PATIENTS') === 'true'

  const showAdditionalCareComponent: boolean =
    iepPatientNames?.length > 0 &&
    !dismisssedComponent &&
    !isIepOnlyExperience &&
    initialOnboardingForIEPOnly &&
    user.roster
      .flatMap((p: Patient) => p.carePlans)
      .every((c: CarePlan) => c.isIep)

  const handleDismissAdditionalCareComponent = () => {
    setIsDismissedMessaging(true)
    localStorage.setItem('DOTCOM_DISMISSED_ADDITIONAL_CARE', 'true')
  }

  const everyPatientIsDisabled: boolean = user.roster.every(
    (p: Patient) => p?.isDisabled
  )

  if (!resultProviders?.length) return null

  return (
    <>
      {/* IEP - Additional care */}
      <DashboardElement
        title={`Additional care is now available for ${enumeratedElementsFromArray(
          iepPatientNames
        )}!`}
        hidden={
          !showAdditionalCareComponent ||
          isDismissedMessaging ||
          everyPatientIsDisabled
        }
      >
        <p className="whitespace-pre-wrap text-base">
          Great news, {enumeratedElementsFromArray(iepPatientNames)}{' '}
          {iepPatientNames?.length === 1 && iepPatientNames[0] !== 'you'
            ? 'is'
            : 'are'}{' '}
          now eligible to book additional mental health therapy sessions outside
          of school!{'\n'}Get started today! Questions? Contact{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="text-cta-default underline"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
          .
        </p>
        <BookNewSessionBtn
          withAvatarsBtn
          onClickBookNewSession={handleDismissAdditionalCareComponent}
        />
        <button
          className="self-center font-semibold underline"
          onClick={handleDismissAdditionalCareComponent}
        >
          Dismiss
        </button>
      </DashboardElement>

      {/* Upcoming sessions */}
      <DashboardElement
        title="Upcoming Sessions"
        hidden={!sessions?.length}
        rightTitleButton={
          <Link
            to="/bookings"
            className="text-base font-medium text-cta-default underline xs:text-sm"
          >
            View All Sessions
          </Link>
        }
      >
        {/* Actual sessions */}
        <div className="flex flex-col gap-8">
          {React.Children.toArray(
            sessions.map((session: Session) => (
              <SessionComponent session={session} />
            ))
          )}
        </div>

        {/* Book new session btn */}
        {!isIepOnlyExperience && <BookNewSessionBtn dottedLargeBtn />}
      </DashboardElement>

      {/* No upcoming sessions */}
      <DashboardElement
        title="No Upcoming Sessions"
        hidden={
          Boolean(sessions?.length) ||
          user.roster.every((p) => !p.allowedToBook) ||
          !user.roster.some((p) => p?.carePlans?.length > 0) ||
          (dontShowBookNewSessionBtn && !isIepOnlyExperience) ||
          everyPatientIsDisabled
        }
      >
        {isIepOnlyExperience || showAdditionalCareComponent ? (
          <Link to="/resources" className={secondaryButtonClass}>
            <img src={LIBRARY_ICON} alt="library" />
            Check out our resource library!
          </Link>
        ) : (
          <BookNewSessionBtn withAvatarsBtn />
        )}
      </DashboardElement>

      {/* Session rating */}
      <SessionRatingModal
        session={sessionToRate}
        setOpen={setOpenSessionRating}
        open={openSessionRating}
      />
    </>
  )
}

export default Sessions
